<template>
  <div class="user-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
let validReservationName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("预约人格式不对"));
      } else {
        callback();
      }
    }
  };
  let validLandlordName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("房东格式不对"));
      } else {
        callback();
      }
    }
  };
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "约看列表",
        search: [
          {
            key: "reservationName",
            label: "预约人",
            maxlength: 16,
              rules: [{
                validator: validReservationName,
                trigger: ['blur']
              }]
          },
          //   {
          //     key: "reservationPhone",
          //     label: "预约人电话"
          //   },
          {
            key: "appointTime",
            label: "期待看房时间",
            type: "date",
            timeType: "yyyy-MM-dd"
          },
          {
            key: "landlordName",
            label: "房东",
            maxlength: 16,
              rules: [{
                validator: validLandlordName,
                trigger: ['blur']
              }]
          }
        ],
        columns: [
          //   {
          //     label: "姓名",
          //     key: "userName",
          //     width: 120,
          //     on: row => {
          //       _this.$router.push("/main/user/detail/show/" + row.userId);
          //     }
          //   },

          { label: "提交时间", key: "createDate" },
          { label: "预约人", key: "reservationName" },
          { label: "性别", key: "gender" },
          { label: "预约人电话", key: "reservationPhone" },
          { label: "期待看房时间", key: "appointTime" },
          { label: "房间", key: "apartment" },
          { label: "房东", key: "landlordName" },
          { label: "房东电话", key: "landlordPhone" }
        ]
      }
    };
  },
  created() {
    console.log("user-list created!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        ...opt.searchForm
      };
      this.post(
        "tenant-service/appointmentrecord/queryAppointmentRecordList",
        dto,
        {
          isUseResponse: true
        }
      ).then(res => {
        console.log(res);

        if (res.data.code == 0) {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            if (item.gender == 2) {
              item.gender = "女";
            } else {
              item.gender = "男";
            }
            if(this.cache.getLS("userInfo")["roleType"] != "8"){
            item.reservationPhone = item.reservationPhone
              ? item.reservationPhone.substring(0, 3) +
                "****" +
                item.reservationPhone.substring(7, item.reservationPhone.length)
              : "";
            item.landlordPhone = item.landlordPhone
              ? item.landlordPhone.substring(0, 3) +
                "****" +
                item.landlordPhone.substring(7, item.landlordPhone.length)
              : "";
            }
            let roleArray = [];
            // item.roleInfoList.forEach(datas => {
            //   roleArray.push(datas.roleName);
            // });
            item.roleTypeStr = roleArray.length ? roleArray.join(",") : "";
            //
            if (item.userState == 1) {
              item.bk = 2;
            } else {
              item.bk = 0;
            }
            item.appointTime = item.appointTime ? moment(item.appointTime).format('YYYY-MM-DD') : '--';
          }
        }
        opt.cb(res.data);
      });
    },
    // 获取角色列表
    getRoleList() {
      return this.post("user-service/apartmentuser/selectRoleList", {}).then(
        res => {
          let returnData = {};
          res.forEach(data => {
            returnData[data.roleId] = returnData[data.roleName];
          });
          return returnData;
        }
      );
    }
  }
};
</script>